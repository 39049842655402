
import React from 'react'
import styled from 'styled-components'

const Page = styled.div`
    margin: 5%;
`

export default function({ pageContext: { node } }) {

    return <Page dangerouslySetInnerHTML={{ __html: node.html }} />

}